import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import logoDesktop from "../static/chambahorro_logo_1.svg";
import logoMobile from "../static/chambahorro_logo_2.svg";
import { Pilot } from "./Pilot";

const Header = (props) => {

    return (
        <div className='sticky top-0 '>
            <div className="bg-white w-full py-3 md:py-3.5 xl:py-4 px-8 md:px-10 xl:px-48 flex items-center content-center">
                <img className="hidden md:block" src={logoDesktop} />
                <img className="block md:hidden h-10 w-28" src={logoMobile} />
                {/*<Link to="planes" className="hidden md:block ml-auto text-gray hover:text-black text-base font-semibold cursor-pointer montserrat" spy={true} smooth={true} offset={50} duration={500}>Ver Planes</Link>*/}
                <Link to="comoFunciona" className="hidden md:block ml-auto text-gray hover:text-black text-base font-semibold cursor-pointer montserrat" spy={true} smooth={true} offset={50} duration={500}>¿Cómo funciona?</Link>
                <Link to="nuevoplan" className="block md:hidden ml-auto md:ml-8 py-3 md:py-4 px-8 md:px-8 md:px-16 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-xs md:text-base font-semibold montserrat">Ver planes</Link>
                <Link to="requisitos" className="hidden md:block ml-8 text-gray hover:text-black text-base font-semibold cursor-pointer montserrat" spy={true} smooth={true} offset={50} duration={500}>Requisitos</Link>
                {/*<button className="ml-auto md:ml-8 py-2 md:py-2.5 px-3 md:px-5 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-xs md:text-base font-semibold montserrat" onClick={() => {window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port+"/registro");}}>Regístrate aquí</button>*/}
                <Link to="planes" className="hidden md:block ml-8 py-2 md:py-2.5 px-3 md:px-5 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-xs md:text-sm lg:text-base font-semibold montserrat cursor-pointer" spy={true} smooth={true} offset={50} duration={500}>Ver Planes</Link>
                
            </div>
            {/*<Pilot />*/}
        </div>
        
    )
}

export { Header }