import respaldo from "../static/chambahorro_respaldo.svg";

const Footer = () => {

    return (
        <div className="w-full py-16 md:py-14 md:py-28 px-8 md:px-10 xl:px-48">
            <div className="w-full flex items-center grid grid-cols-1 md:grid-cols-3 gap-0 md:gap-4">
                <div>
                    <h1 className="text-base font-semibold geometria">Contáctanos</h1>
                    <h1 className="mt-2 text-base font-normal geometria">Correo: contacto@chambahorro.pe</h1>
                    <h1 className="mt-4 text-base font-normal geometria">WhatsApp: (+51) 983 844 993</h1>
                </div>

                <div className="mt-12 md:mt-0">
                    <h1 className="text-base font-semibold geometria">Legales</h1>
                    <h1 className="mt-2 text-base font-normal cursor-pointer underline geometria"><a rel="noopener noreferrer" href="https://storage.cloud.google.com/legal-chambahorro/T%C3%A9rminos%20y%20condiciones%20Chambahorro.pdf" target="_blank">Términos y Condiciones</a></h1>
                    <h1 className="mt-4 text-base font-normal cursor-pointer underline geometria"><a rel="noopener noreferrer" href="https://storage.cloud.google.com/legal-chambahorro/Pol%C3%ADticas%20de%20privacidad%20Chambahorro.pdf" target="_blank">Política de Privacidad de datos</a></h1>
                </div>

                <div className="mt-12 md:mt-0">
                    <h1 className="text-base font-semibold geometria">¿Quiénes somos?</h1>
                    <h1 className="mt-2 text-base font-normal row-span-2 text-left geometria">Chambahorro busca ayudarte a obtener un préstamo para tu negocio a través de tu comportamiento de ahorro.</h1>
                </div>
            </div>
            <div className="w-full mt-4 flex items-center grid grid-cols-3 gap-0 md:gap-4">
                <div className="col-span-0 md:col-span-2"></div>
                <div className="flex items-center col-span-3 md:col-span-1">
                    <img className="" src={respaldo} />
                </div>
            </div>

            <hr className="my-8 md:my-14"></hr>

            <h1 className="text-base font-normal text-center geometria">2023 Chambahorro. Todos los derechos reservados</h1>
        </div>
    )
}

export { Footer }