import { } from "@fortawesome/free-regular-svg-icons";

const ModalLoad = (props) => {

    return (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-700 bg-opacity-75 transition-opacity">
            {/*<img src={require('../static/spinner.gif')} /> */}
            <svg className="svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="45"/>
            </svg>
        </div>
    )
}

export { ModalLoad }