import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import logoDesktop from "../../static/chambahorro_logo_1.svg";
import logoMobile from "../../static/chambahorro_logo_2.svg";
import { useEffect } from "react";
import { Steps } from "../../components/Steps";
import { ModalLoad } from "../../components/ModalLoad";
import { WhatsApp } from "../../components/WhatsApp";
import { useCookies } from 'react-cookie';
import { toFormData } from "../../common/to-formdata";
import { enviroment } from "../../enviroment/enviroment";

const Registry2 = () => {
    const [cuenta, setCuenta] = useState('');
    const [creacion, setCreacion] = useState(false);
    const [politica, setPolitica] = useState(false);
    const [terminos, setTerminos] = useState(false);
    const [envio, setEnvio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookies] = useCookies();

    useEffect(() => {
        //eventOne();
    }, [])

    const goLanding = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
    }

    const goStep1 = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/c3RlcDE=");
    }

    const goStep3 = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/c3RlcDM=");
    }

    const changeCuenta = (e) => {
        setCuenta(e.target.value);
    }

    const changeCreacion = (e) => {
        setCreacion(!creacion);
    }

    const changePolitica = (e) => {
        setPolitica(!politica);
    }

    const changeTerminos = (e) => {
        setTerminos(!terminos);
    }

    const sendInfo = (event) => {
        if (!(cuenta.length !== 13 || !creacion || !politica || !terminos)) {
            setLoading(true);
            var d = new Date(),
                dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
            var info = JSON.parse(window.sessionStorage.getItem("info"));
            info.actualizacion = dformat;
            info.cuenta = cuenta;
            info.creacion = creacion ? 'Si' : 'No';
            info.politica = politica ? 'Si' : 'No';
            info.terminos = terminos ? 'Si' : 'No';

            const requestOptions = {
                mode: 'no-cors',
                method: 'POST',
                body: toFormData(info)
            };
            fetch(enviroment.appSriptsSolicitaPrestamo, requestOptions)
            .then(() => {
                goStep3();
                //resetInfo();
                //props.setLoading(false);
                //props.setResult(1);
                //props.setShowModal(false);
            })
            .catch((er) => {
                setLoading(false);
                //props.setLoading(false);
                //props.setResult(-1);
                //props.setShowModal(false);
                //console.log(er);
            })
        }
        else {
            setEnvio(true);
        }

        
    }

    return (
        <div>
            <div className="bg-white w-full py-3 md:py-3.5 xl:py-4 px-8 md:px-10 xl:px-48 flex items-center content-center">
                <img className="hidden md:block cursor-pointer" src={logoDesktop} onClick={goLanding} />
                <img className="block md:hidden h-10 w-28" src={logoMobile} onClick={goLanding} />
            </div>

            <Steps></Steps>

            <div className="bg-steps px-8 md:px-10 xl:px-96 py-6 md:py-8 xl:py-11">
                <h1 className="text-sky font-bold text-xl montserrat">Paso 2</h1>
                <h1 className="text-black text-2xl mt-4 montserrat">Califica al préstamo abriendo una cuenta nueva</h1>
                <h1 className="text-black text-base mt-4 montserrat">Esta nueva cuenta la congelaremos para que comiences ahorrar. <span className="font-bold">Por favor, no registres una cuenta antigua.</span></h1>
                {/*<h1 className="text-black text-sm xl:text-base font-bold mt-4 montserrat">Recuerda:</h1>
                <ul className="text-black text-sm xl:text-base montserrat list-disc list-outside pl-4">
                    <li>El monto ahorrado en esta cuenta será congelado como garantía.</li>
                    <li>La nueva cuenta debe estar a tu nombre.</li>
                </ul>*/}
            </div>

            <div className="px-8 md:px-10 xl:px-96">
                <h1 className="text-base xl:text-base montserrat mt-6 xl:mt-12">1. Abre una nueva Cuenta Simple Soles y <span className="font-semibold"> copia el número de cuenta.</span></h1>

                <div className="flex items-center rounded-b mt-4 xl:mt-5">
                    <a className="py-4 md:py-2 xl:py-4 px-20 md:px-16 xl:px-20 rounded-md text-white bg-red-600 border-2 hover:bg-red-500 text-sm xl:text-base font-semibold montserrat cursor-pointer"
                    href="https://interbank.pe/cuentas/cuentas-ahorro/cuenta-simple" target="_blank">Abrir Cuenta Simple</a>
                </div>

                <h1 className="text-base montserrat mt-14 xl:mt-12">2. Regresa a esta pantalla y <span className="font-bold"> pega el  número de cuenta </span> en la casilla.</h1>

                <div className="grid grid-cols-1 md:grid-cols-1 xl:grid-cols-1 gap-0 xl:gap-9">
                    <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">Escribe los 13 dígitos de la cuenta</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="number" maxLength={13} className={(cuenta.length !== 13 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={cuenta} onChange={changeCuenta} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(cuenta.length !== 13 && envio)}>Debes ingresar los 13 dígitos</label>
                    </div>
                </div>

                <div className="w-100 bg-minisky rounded-lg py-4 px-4 text-justify flex mt-6 xl:mt-12">
                    <img className="w-6 h-6" src={require('../../static/chambahorro_icon5.png')} />
                    <h1 className="text-black text-sm xl:text-sm montserrat pl-4">
                        Recuerda que congelaremos esta cuenta, en caso quieras disponer de tu dinero ahorrado, escríbenos al WhatsApp.
                    </h1>
                </div>

                <div className="items-center text-justify w-full mt-12 xl:mt-14">
                    <div className="flex items-center">
                        <input type="checkbox" name="validacion" className="text-ibk rounded-sm border-green-600 w-6 h-6 xl:w-6 xl:h-6" checked={creacion} onChange={changeCreacion} />
                        <h1 className="ml-2 text-sm md:text-sm xl:text-sm font-normal geometria text-left">Acepto que mi nueva cuenta sea congelada para comenzar a ahorrar en Chambahorro.</h1>
                    </div>
                    <label className="text-sm xl:text-sm text-red-500 geometria" hidden={!(!creacion && envio)}>Debes aceptar dejar un número de Cuenta Simple</label>
                </div>

                <div className="items-center text-justify w-full pt-3 md:pt-4 xl:pt-5">
                    <div className="flex items-center">
                        <input type="checkbox" name="validacion" className="text-ibk rounded-sm border-green-600 w-6 h-6 xl:w-6 xl:h-6" checked={politica} onChange={changePolitica} />
                        <h1 className="ml-2 text-sm md:text-sm xl:text-sm font-normal geometria text-left">He leído y acepto la <a className="underline cursor-pointer text-ibk" rel="noopener noreferrer" href="https://storage.cloud.google.com/legal-chambahorro/Pol%C3%ADticas%20de%20privacidad%20Chambahorro.pdf" target="_blank">Política de Privacidad de datos</a>.</h1>
                    </div>
                    <label className="text-sm xl:text-sm text-red-500 geometria" hidden={!(!politica && envio)}>Debes aceptar la Política de Privacidad de datos</label>
                </div>

                <div className="items-center text-justify w-full pt-3 md:pt-4 xl:pt-5">
                    <div className="flex items-center">
                        <input type="checkbox" name="validacion" className="text-ibk rounded-sm border-green-600 w-6 h-6 xl:w-6 xl:h-6" checked={terminos} onChange={changeTerminos} />
                        <h1 className="ml-2 text-sm md:text-sm xl:text-sm font-normal geometria text-left">He leído y acepto los <a className="underline cursor-pointer text-ibk" rel="noopener noreferrer" href="https://storage.cloud.google.com/legal-chambahorro/T%C3%A9rminos%20y%20condiciones%20Chambahorro.pdf" target="_blank">Términos y Condiciones</a>.</h1>
                    </div>
                    <label className="text-sm xl:text-sm text-red-500 geometria" hidden={!(!terminos && envio)}>Debes aceptar los Términos y Condiciones</label>
                </div>

                <div className="flex items-center content-center rounded-b mb-24 xl:mb-20 mt-16 xl:mt-16">
                    <div className="w-full xl:w-fit mx-auto">
                        <button className="w-full xl:w-fit py-4 md:py-2 xl:py-4 px-20 md:px-16 xl:px-20 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-sm xl:text-base font-semibold montserrat" onClick={sendInfo}>
                            ¡Quiero mi préstamo!
                        </button>
                        <h1 className="cursor-pointer mt-5 geometria underline text-center" onClick={goStep1}>Volver</h1>
                    </div>
                    
                </div>


            </div>

            <hr></hr>

            <WhatsApp cookies={cookies} setCookies={setCookies}></WhatsApp>
            
            <Footer></Footer>

            {loading ? <ModalLoad /> : null}
        </div>
    )
}

export { Registry2 }