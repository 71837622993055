import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import logoDesktop from "../../static/chambahorro_logo_1.svg";
import logoMobile from "../../static/chambahorro_logo_2.svg";
import { useEffect } from "react";
import { Steps } from "../../components/Steps";
import { ModalLoad } from "../../components/ModalLoad";
import { WhatsApp } from "../../components/WhatsApp";
import { useCookies } from 'react-cookie';
import { enviroment } from "../../enviroment/enviroment";
import { toFormData } from "../../common/to-formdata";

const Registry1 = () => {
    const array = window.location.pathname.split("/");
    const ruta = array[2];
    var val = ruta === undefined || ruta === "0" ? 0 : Number(ruta);
    let info = window.sessionStorage.getItem('info');
    const [plan, setPlan] = useState(info != null && info !== undefined ? JSON.parse(info).plan : val === 4 ? 0 : val);
    const [dni, setDni] = useState(info != null && info !== undefined ? JSON.parse(info).dni : '');
    const [nombres, setNombres] = useState(info != null && info !== undefined ? JSON.parse(info).nombres : '');
    const [apellidos, setApellidos] = useState(info != null && info !== undefined ? JSON.parse(info).apellidos : '');
    // const [correo, setCorreo] = useState('');
    const [celular, setCelular] = useState(info != null && info !== undefined ? JSON.parse(info).celular : '');
    const [envio, setEnvio] = useState(false);
    // const [valEmail, setValEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookies] = useCookies();

    useEffect(() => {
        //eventOne();
    }, [])

    const goLanding = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
    }

    const goStep2 = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/c3RlcDI=");
    }

    const changePlan = (e) => {
        setPlan(e.target.value);
    }

    const changeDni = (e) => {
        setDni(e.target.value);
    }

    const changeNombres = (e) => {
        setNombres(e.target.value);
    }

    const changeApellidos = (e) => {
        setApellidos(e.target.value);
    }

    // const changeCorreo = (e) => {
    //     setCorreo(e.target.value);
    //     var validEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //     if (validEmail.test(e.target.value)) {
    //         setValEmail(true);
    //     }
    //     else {
    //         setValEmail(false);
    //     }
    // }

    const changeCelular = (e) => {
        setCelular(e.target.value);
    }

    const sendInfo = (event) => {
        //event.preventDefault();
        /*var validEmail =  /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if( validEmail.test(correo) ){
            setValEmail(true);
        }
        else{
            setValEmail(false);
        }*/

        if (!(dni.length !== 8 || nombres.length === 0 || apellidos.length === 0 || celular.length !== 9 || plan === 0)) {
            setLoading(true);
            var d = new Date(),
            dformat = [d.getDate(),d.getMonth()+1,d.getFullYear()].join('/') + ' ' + [d.getHours(),d.getMinutes(),d.getSeconds()].join(':');
            console.log(plan);
            var info = {
                dni: dni,
                nombres: nombres,
                apellidos: apellidos,
                celular: celular,
                planid: plan ?? '',
                plan: plan ? enviroment.planes.find((item) => item.value === plan).text : '',
                cuenta: '',
                fecha: dformat,
                origen: origin
            }
            window.sessionStorage.setItem('info', JSON.stringify(info));
            const requestOptions = {
                mode: 'no-cors',
                method: 'POST',
                body: toFormData(info)
            };
            fetch(enviroment.appSriptsSolicitaPrestamo, requestOptions)
            .then(() => {
                goStep2();
            })
            .catch((er) => {
                setLoading(false);
            })
        }
        else {
            setLoading(false);
            setEnvio(true);
        }
    }

    return (
        <div>
            <div className="bg-white w-full py-3 md:py-3.5 xl:py-4 px-8 md:px-10 xl:px-48 flex items-center content-center">
                <img className="hidden md:block cursor-pointer" src={logoDesktop} onClick={goLanding} />
                <img className="block md:hidden h-10 w-28" src={logoMobile} onClick={goLanding} />
            </div>

            <Steps></Steps>

            <div className="bg-steps px-8 md:px-10 xl:px-96 py-6 md:py-8 xl:py-11">
                <h1 className="text-sky font-bold text-xl xl:text-2xl montserrat">Paso 1</h1>
                <h1 className="text-black text-sm xl:text-base mt-4 montserrat">Completa el siguiente formulario, elige un plan chambero y déjanos tus datos.</h1>
            </div>

            <div className="px-8 md:px-10 xl:px-96">
                <h1 className="text-2xl xl:text-3xl geometria mt-9 xl:mt-10">Plan Chambero</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-0 xl:gap-9">
                <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                    <label className="text-sm md:text-base block geometria">Elige un plan chambero</label>
                    <div className="w-full pt-0 xl:pt-1">
                        <select name="plan" className={(plan === 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={plan} onChange={changePlan} >
                            <option value='0'>Selecciona</option>
                            {enviroment.planes.map((item, index) => <option key={index} value={item.value}>{item.text}</option>)}
                        </select>
                    </div>
                    <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(plan === 0 && envio)}>Debes seleccionar un plan</label>
                </div>
                </div>

                <h1 className="text-2xl xl:text-3xl geometria pt-14 xl:pt-20">Datos personales</h1>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-0 xl:gap-9">
                <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                    <label className="text-sm md:text-base block geometria">Número de documento (DNI)</label>
                    <div className="w-full pt-0 xl:pt-1">
                        <input type="number" maxLength={8} className={(dni.length !== 8 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={dni} onChange={changeDni} />
                    </div>
                    <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(dni.length !== 8 && envio)}>Debes ingresar un DNI válido</label>
                </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-0 xl:gap-9">
                    <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">Nombres</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="text" name="nombres" className={(nombres.length === 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={nombres} onChange={changeNombres} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(nombres.length === 0 && envio)}>Debes ingresar tus nombres</label>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-0 xl:gap-9">
                    <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">Apellidos</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="text" name="apellidos" className={(apellidos.length === 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={apellidos} onChange={changeApellidos} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(apellidos.length === 0 && envio)}>Debes ingresar tus apellidos</label>
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-0 xl:gap-9">
                    {/*<div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">Correo</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="email" name="correo" className={(correo.length == 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={correo} onChange={changeCorreo} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!((correo.length == 0 || !valEmail) && envio)}>Debes ingresar un correo válido</label>
                    </div>*/}

                    <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">Número de WhatsApp</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="number" maxLength={9} name="celular" className={(celular.length === 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={celular} onChange={changeCelular} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(celular.length !== 9 && envio)}>Debes ingresar un número de celular válido</label>
                    </div>
                </div>

                <div className="flex items-center content-center rounded-b mb-24 xl:mb-20 mt-16 xl:mt-20">
                    <div className="w-full xl:w-fit mx-auto">
                        <button type="button" className="w-full xl:w-fit py-4 md:py-2 xl:py-4 px-20 md:px-16 xl:px-20 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-sm xl:text-base font-semibold montserrat" onClick={sendInfo}>
                            Siguiente
                        </button>
                        {/*<h1 className="cursor-pointer mt-5 geometria underline text-center" onClick={goLanding}>Volver</h1>*/}
                    </div>
                </div>

                
            </div>

            <hr></hr>
            <Footer></Footer>

            <WhatsApp cookies={cookies} setCookies={setCookies}></WhatsApp>

            {loading ? <ModalLoad /> : null}
        </div>
    )
}

export { Registry1 }