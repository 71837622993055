import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Landing } from './pages/Landing/Landing';
import { PageNotFound } from './pages/PageNotFound/PageNotFound';
import React, { useState } from "react";
import { Registry } from './pages/Registry/Registry';
import { Registry1 } from './pages/RegistryNew/Registry1';
import { Registry2 } from './pages/RegistryNew/Registry2';
import { Registry3 } from './pages/RegistryNew/Registry3';

const array = window.location.pathname.split("/");
const ruta = array[1];

function App() {
  const token = null;
  if (token === null) {
    if (ruta === '') {
      return (<Landing />);
    }
    if (ruta === 'registro') {
      return (<Registry />);
    }
    if (ruta === 'c3RlcDE=') {
      return (<Registry1 />);
    }
    if (ruta === 'c3RlcDI=') {
      return (<Registry2 />);
    }
    if (ruta === 'c3RlcDM=') {
      return (<Registry3 />);
    }
  }
  return (
    <Router>
      <div className=' ' id="App">
        <Routes>
          {/*<Route path="/" exact={true} element={<Home />}> </Route>
          <Route path="/home" exact={true} element={<Home />}> </Route>*/}
          <Route path="*" element={<PageNotFound />}> </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;