
import background from "../static/chambahorro_rectangle.png";
import background2 from "../static/chambahorro_rectangle2.png";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Plans } from "./Plans";

const Banner = (props) => {

    const setShowModal = () => {
        
    }

    return (
        <div className="w-full bg-green">

                <div className="hidden md:block w-full">
                    <div className="w-full" style={{ backgroundImage: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', }}>
                        <div className="pt-12 xl:pt-18 px-10 xl:px-48 w-full h-full flex items-center grid grid-cols-10 xl:grid-cols-2 gap-4 xl:gap-16">
                            <div className="col-span-6 xl:col-span-1">
                                <h1 className="text-5xl xl:text-5xl font-semibold text-white geometria"><span className="text-blue">Ahorra </span> y podrás</h1>
                                <h1 className="text-5xl xl:text-5xl font-semibold text-white mt-2 geometria">obtener tu</h1>
                                <h1 className="text-5xl xl:text-5xl font-semibold text-white mt-2 geometria"><span className="text-blue">préstamo </span> con</h1>
                                <h1 className="text-5xl xl:text-5xl font-semibold text-white mt-2 geometria">Chambahorro</h1>
                                <h1 className="text-2xl font-normal text-white my-7 geometria">Chambahorro te presta hasta 5 veces tu ahorro mensual</h1>
                                <Element name="planes"></Element>
                                {/*<button className="py-4 px-16 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base leading-5 font-semibold montserrat" onClick={() => {window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port+"/registro");}}>Me interesa</button>*/}
                            </div>
                            <div className="col-span-4 xl:col-span-1">
                                <img className="w-4/5 mx-auto" src={require('../static/chambahorro_banner.png')} />
                            </div>
                        </div>
                        <div className="px-10 xl:px-48 w-full">
                            <Plans setShowModal={setShowModal} />
                            <Element name="comoFunciona"></Element>
                        </div>
                    </div>
                </div>

                <div className="block md:hidden w-full">
                    <div className="w-full" style={{ backgroundImage: `url(${background2})`, backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%', }}>
                        <div className="pt-12 px-8 w-full h-full flex items-center grid grid-cols-1">
                            <div className="">
                                <h1 className="font-semibold text-white geometria text-center" style={{ fontSize: 32 }}><span className="text-blue">Ahorra </span> y podrás</h1>
                                <h1 className="font-semibold text-white geometria text-center" style={{ fontSize: 32 }}>obtener tu</h1>
                                <h1 className="font-semibold text-white geometria text-center" style={{ fontSize: 32 }}><span className="text-blue">préstamo </span> con</h1>
                                <h1 className="font-semibold text-white geometria text-center" style={{ fontSize: 32 }}>Chambahorro</h1>
                                <Element name="nuevoplan"></Element>
                                <h1 className="text-xl font-normal text-white mt-3 mb-10 geometria text-center">Chambahorro te presta hasta 5 veces tu ahorro mensual</h1>
                                {/*<button className="w-full mt-8 py-4 px-16 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base leading-5 font-semibold montserrat" onClick={() => {window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port+"/registro");}}>Me interesa</button>*/}
                            </div>
                        </div>
                        <div className="w-full">
                            <Plans setShowModal={setShowModal} />
                        </div>
                    </div>
                </div>
            </div>
    )
}

export { Banner }