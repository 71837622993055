import requirements from "../static/chambahorro_requirements.svg";

const Requirements = () => {

    return (
        <div className="w-full py-16 md:py-14 md:py-28 px-8 md:px-10 xl:px-48 bg-gray">
            <h1 className="block xl:hidden text-3xl xl:text-5xl text-center geometria ">Lo que necesitas para acceder al préstamo</h1>
            <div className="flex items-center w-full grid-cols-1 xl:grid-cols-2">
                {/*<img className="hidden xl:block w-80 h-80" src={requirements} />*/}
                <div className="hidden xl:block w-1/2 mr-0 xl:mr-12">
                    <h1 className="text-3xl xl:text-5xl text-left geometria ">Lo que necesitas para acceder al préstamo</h1>
                </div>
                <div className="w-full xl:w-1/2 ml-0 xl:ml-12">
                    <div className="w-full flex items-center mt-8">
                        <img className="w-2 h-2" src={require('../static/elipse.png')} />
                        <h1 className="ml-4 geometria text-sm xl:text-base">Depositar tu ahorro puntualmente.</h1>
                    </div>
                    <div className="w-full flex items-center mt-8">
                        <img className="w-2 h-2" src={require('../static/elipse.png')} />
                        <h1 className="ml-4 geometria text-sm xl:text-base">No tener deudas en otras instituciones financieras.</h1>
                    </div>
                    <div className="w-full flex items-center mt-8">
                        <img className="w-2 h-2" src={require('../static/elipse.png')} />
                        <h1 className="ml-4 geometria text-sm xl:text-base">Ser residente en Perú y contar con DNI o carné de extranjería.</h1>
                    </div>
                    <div className="w-full flex items-center mt-8">
                        <img className="w-2 h-2" src={require('../static/elipse.png')} />
                        <h1 className="ml-4 geometria text-sm xl:text-base">Ser mayor de edad.</h1>
                    </div>
                    <div className="w-full flex items-center mt-8">
                        <img className="w-2 h-2" src={require('../static/elipse.png')} />
                        <h1 className="ml-4 geometria text-sm xl:text-base">Pasar pre-evaluación.</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { Requirements }