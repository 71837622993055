import React, { useState } from "react";
import { enviroment } from "../enviroment/enviroment";
import { toFormData } from "../common/to-formdata";

const Form = (props) => {
  const [dni, setDni] = useState("");
  const [nombres, setNombres] = useState("");
  const [apellidos, setApellidos] = useState("");
  const [correo, setCorreo] = useState("");
  const [celular, setCelular] = useState("");
  const [politica, setPolitica] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [envio, setEnvio] = useState(false);
  const [valEmail, setValEmail] = useState(false);
  const array = window.location.pathname.split("/");
  const ruta = array[2];
  var val = ruta === undefined || ruta === "0" ? 0 : Number(ruta);
  const [plan, setPlan] = useState(val === 1 || val === 2 || val === 3 ? val : 0);

  const changeDni = (e) => {
    setDni(e.target.value);
  };

  const changeNombres = (e) => {
    setNombres(e.target.value);
  };

  const changeApellidos = (e) => {
    setApellidos(e.target.value);
  };

  const changeCorreo = (e) => {
    setCorreo(e.target.value);
    var validEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (validEmail.test(e.target.value)) {
      setValEmail(true);
    } else {
      setValEmail(false);
    }
  };

  const changeCelular = (e) => {
    setCelular(e.target.value);
  };

  const changePlan = (e) => {
    setPlan(e.target.value);
  };

  const changePolitica = (e) => {
    setPolitica(!politica);
  };

  const changeTerminos = (e) => {
    setTerminos(!terminos);
  };

  const sendInfo = (event) => {
    event.preventDefault();

    if (!(dni.length !== 8 || nombres.length === 0 || apellidos.length === 0 || celular.length !== 9 || plan === 0 || !politica || !terminos)) {
      props.setLoading(true);
      var d = new Date(),
        dformat = [d.getDate(), d.getMonth() + 1, d.getFullYear()].join("/") + " " + [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
      var info = {
        dni: dni,
        nombres: nombres,
        apellidos: apellidos,
        celular: celular,
        planid: plan,
        plan: enviroment.planes.find((item) => item.value === plan).text,
        fecha: dformat,
        politicas: politica ? 'Si' : 'No',
        terminos: terminos ? 'Si' : 'No',
      };
      
      const requestOptions = {
        mode: "no-cors",
        method: "POST",
        body: toFormData(info),
      };
      fetch(enviroment.appSriptsPlanes, requestOptions)
        .then(() => {
          resetInfo();
          props.setLoading(false);
          props.setResult(1);
          props.setShowModal(false);
        })
        .catch((er) => {
          props.setLoading(false);
          props.setResult(-1);
          props.setShowModal(false);
          console.log(er);
        });
    } else {
      setEnvio(true);
    }
  };

  const resetInfo = () => {
    setDni("");
    setNombres("");
    setApellidos("");
    setCorreo("");
    setCelular("");
    setPlan(0);
    setPolitica(false);
    setTerminos(false);
    setValEmail(false);
    props.setResult(0);
    props.setLoading(false);
    setEnvio(false);
  };

  return (
    <>
      <div className="mx-10 md:mx-16 xl:mx-20 flex-auto text-center">
        <h1 className="text-base md:text-base xl:text-2xl font-semibold geometria">Envíanos tu información para que podamos contactarnos contigo</h1>
      </div>

      <div className="mx-10 md:mx-16 xl:mx-20 flex-auto">
        <form className="w-full formulario" onSubmit={sendInfo}>
          <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
            <label className="text-sm md:text-base block geometria">*Nro. DNI</label>
            <div className="w-full pt-0 xl:pt-1">
              <input
                type="number"
                maxLength={8}
                className={
                  dni.length !== 8 && envio
                    ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max"
                    : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"
                }
                defaultValue={dni}
                onChange={changeDni}
              />
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(dni.length !== 8 && envio)}>
              Debes ingresar un DNI válido
            </label>
          </div>

          <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
            <label className="text-sm md:text-base block geometria">*Nombres</label>
            <div className="w-full pt-0 xl:pt-1">
              <input
                type="text"
                name="nombres"
                className={
                  nombres.length === 0 && envio
                    ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max"
                    : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"
                }
                defaultValue={nombres}
                onChange={changeNombres}
              />
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(nombres.length === 0 && envio)}>
              Debes ingresar tus nombres
            </label>
          </div>

          <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
            <label className="text-sm md:text-base block geometria">*Apellidos</label>
            <div className="w-full pt-0 xl:pt-1">
              <input
                type="text"
                name="apellidos"
                className={
                  apellidos.length === 0 && envio
                    ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max"
                    : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"
                }
                defaultValue={apellidos}
                onChange={changeApellidos}
              />
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(apellidos.length === 0 && envio)}>
              Debes ingresar tus apellidos
            </label>
          </div>

          {/*<div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
                        <label className="text-sm md:text-base block geometria">*Correo</label>
                        <div className="w-full pt-0 xl:pt-1">
                            <input type="email" name="correo" className={(correo.length == 0 && envio) ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max" : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"} defaultValue={correo} onChange={changeCorreo} />
                        </div>
                        <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!((correo.length == 0 || !valEmail) && envio)}>Debes ingresar un correo válido</label>
                    </div>*/}

          <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
            <label className="text-sm md:text-base block geometria">*Número de celular</label>
            <div className="w-full pt-0 xl:pt-1">
              <input
                type="number"
                maxLength={9}
                name="celular"
                className={
                  celular.length === 0 && envio
                    ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max"
                    : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"
                }
                defaultValue={celular}
                onChange={changeCelular}
              />
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(celular.length !== 9 && envio)}>
              Debes ingresar un número de celular válido
            </label>
          </div>

          <div className="items-center justify-center w-full pt-3 md:pt-4 xl:pt-5">
            <label className="text-sm md:text-base block geometria">*Elige un plan chambero</label>
            <div className="w-full pt-0 xl:pt-1">
              <select
                name="plan"
                className={
                  plan === 0 && envio
                    ? "text-sm md:text-base w-full rounded border-red-500 geometria h-max md:h-8 xl:h-max"
                    : "text-sm md:text-base w-full rounded geometria h-max md:h-8 xl:h-max"
                }
                defaultValue={plan}
                onChange={changePlan}
              >
                <option value="0">Selecciona</option>
                {enviroment.planes.map((item, index) => <option key={index} value={item.value}>{item.text}</option>)}
              </select>
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(plan === 0 && envio)}>
              Debes seleccionar un plan
            </label>
          </div>

          <div className="items-center text-justify w-full pt-3 md:pt-4 xl:pt-5">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="validacion"
                className="text-ibk rounded-sm border-green-600 w-6 h-6 xl:w-6 xl:h-6"
                checked={politica}
                onChange={changePolitica}
              />
              <h1 className="ml-2 text-xs md:text-xs xl:text-sm font-normal geometria text-left">
                He leído y acepto la{" "}
                <a
                  className="underline cursor-pointer text-ibk"
                  rel="noopener noreferrer"
                  href="https://storage.cloud.google.com/legal-chambahorro/Pol%C3%ADticas%20de%20privacidad%20Chambahorro.pdf"
                  target="_blank"
                >
                  Política de Privacidad de datos
                </a>
                .
              </h1>
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(!politica && envio)}>
              Debes aceptar la Política de Privacidad de datos
            </label>
          </div>

          <div className="items-center text-justify w-full pt-3 md:pt-4 xl:pt-5">
            <div className="flex items-center">
              <input
                type="checkbox"
                name="validacion"
                className="text-ibk rounded-sm border-green-600 w-6 h-6 xl:w-6 xl:h-6"
                checked={terminos}
                onChange={changeTerminos}
              />
              <h1 className="ml-2 text-xs md:text-xs xl:text-sm font-normal geometria text-left">
                He leído y acepto los{" "}
                <a
                  className="underline cursor-pointer text-ibk"
                  rel="noopener noreferrer"
                  href="https://storage.cloud.google.com/legal-chambahorro/T%C3%A9rminos%20y%20condiciones%20Chambahorro.pdf"
                  target="_blank"
                >
                  Términos y Condiciones
                </a>
                .
              </h1>
            </div>
            <label className="text-xs xl:text-sm text-red-500 geometria" hidden={!(!terminos && envio)}>
              Debes aceptar los Términos y Condiciones
            </label>
          </div>

          <div className="flex items-center justify-center rounded-b mb-5 xl:mb-8 mt-5 xl:mt-8">
            <button className="py-4 md:py-2 xl:py-4 px-20 md:px-16 xl:px-20 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-sm xl:text-base font-semibold montserrat">
              ¡Enviar!
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export { Form };
