import React, { useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WhatsApp } from "../../components/WhatsApp";
import { Footer } from "../../components/Footer";
import { ModalSuccess } from "../../components/ModalSuccess";
import { Banner } from "../../components/Banner";
import { HowWork } from "../../components/HowWork";
import { Plans } from "../../components/Plans";
import { Question } from "../../components/Questions";
import { Form } from "../../components/Form";
import { Header } from "../../components/Header";
import { Cookies } from "../../components/Cookies";
import { useCookies } from 'react-cookie';
import { Requirements } from "../../components/Requirements";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { ModalLoad } from "../../components/ModalLoad";

const Landing = () => {
    const [showModal, setShowModal] = useState(false);
    const [result, setResult] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cookies, setCookies] = useCookies();

    const goLanding = () => {
        
    }

    const modal = (
        <div className="px-4 py-4 md:py-0 xl:py-4 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-700 bg-opacity-75 transition-opacity">
            <div className="w-140 md:w-140 xl:w-160">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="grid justify-items-end p-3 md:p-1 xl:p-3 rounded-t">
                        <button className="bg-transparent border-0" onClick={() => setShowModal(false)}>
                            <FontAwesomeIcon className="opacity-7 h-6 xl:h-8 w-6 xl:w-8 text-xl block" icon={faClose} />
                        </button>
                    </div>
                    <Form setResult={setResult} setLoading={setLoading} setShowModal={setShowModal}></Form>
                </div>
            </div>
        </div>
    );

    return (
        <div className="">
            <Header setShowModal={setShowModal}></Header>

            <Banner setShowModal={setShowModal} />

            <HowWork />

            {/*<Plans setShowModal={setShowModal} />*/}
            <Requirements />

            <Question />

            <hr></hr>

            <Footer />

            <WhatsApp cookies={cookies} setCookies={setCookies} />

            {cookies.Acept == undefined || cookies.Acept == "No" ? <Cookies setCookies={setCookies} /> : null}

            {loading ? <ModalLoad /> : null}

            {showModal ? modal : null}

            {result == 1 ? <ModalSuccess setResult={setResult} goLanding={goLanding}/> : null}

            {result == 2 ? <ModalSuccess setResult={setResult} /> : null}

        </div>
    );
}

export { Landing }