import respaldo from "../static/chambahorro_steps.png";

const Steps = () => {

    return (
        <div className="w-full h-24 md:h-40 bg-green flex items-center">
            <div className="w-full flex items-center px-8 md:px-10 xl:px-96">
                <div className="w-full xl:w-1/2 flex items-center">
                    <div className="text-base xl:text-3xl text-white text-center xl:text-left geometria">Solicita aquí tu préstamo con Chambahorro</div>
                </div>
                <div className="w-0 xl:w-1/2 py-1">
                    <img className="ml-auto hidden xl:block" src={respaldo} />
                </div>
            </div>
        </div>
    )
}

export { Steps }