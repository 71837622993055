const Cookies = (props) => {

    const setCookies = () => {
        props.setCookies('Acept', "Yes", { path: '/' });
    }

    return (
            <div className="bg-gray-new w-full flex items-center justify-center text-white fixed z-50 bottom-0 right-0">
                <div className="w-full px-4 py-2 block md:flex items-center">
                    <div className="montserrat w-full md:w-10/12">
                        <h1 className="text-base md:text-lg font-semibold">Política de Cookies</h1>
                        <h1 className="text-xs md:text-sm mt-2">Esta página utiliza cookies analíticas para mejorar tu experiencia de navegación.
                            Puedes aceptar las cookies pulsando el botón “Aceptar”. Para obtener más información, visita nuestra <a className="text-sky font-semibold"
                            rel="noopener noreferrer" href="https://storage.cloud.google.com/legal-chambahorro/Politicas%20de%20cookies%20Chambahorro.pdf" target="_blank">Política de Cookies</a>.
                        </h1>
                    </div>
                    <div className="w-full md:w-2/12 mt-2 md:mt-0">
                        <button className="w-full py-2 px-4 rounded bg-green text-xs md:text-base"
                            onClick={setCookies}>Aceptar</button>
                    </div>
                </div>
            </div>

    )
}

export { Cookies }