import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { } from "@fortawesome/free-solid-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsApp = (props) => {
    
    return (
        <div>
                <a href="https://wa.me/51983844993?text=Hola%20Chambahorro,%20tengo%20una%20consulta."
                    className={props.cookies.Acept == undefined || props.cookies.Acept == "No" ? "whatsapp flex items-center justify-center text-white h-16 sm:h-20 w-16 sm:w-20 fixed text-2xl sm:text-3xl rounded-full z-50 bottom-40 sm:bottom-28 right-4 sm:right-20"
                    : "whatsapp flex items-center justify-center text-white h-16 sm:h-20 w-16 sm:w-20 fixed text-2xl sm:text-3xl rounded-full z-50 bottom-10 sm:bottom-20 right-4 sm:right-20"} target="_blank" title="Contactar a soporte por whatsapp">
                    <FontAwesomeIcon icon={faWhatsapp} size="xl" />
                </a>
        </div>

    )
}

export { WhatsApp }