const Plans = (props) => {
  return (
    <div className="pb-12 xl:pb-18 ">
      <div className="hidden md:grid grid-cols-3 gap-10 mt-16">
        <div className="rounded-2xl bg-white px-3.5 xl:px-8 py-10 xl:py-12 shadow-sm">
          <h1 className="text-center text-lg xl:text-xl font-normal geometria">Te prestamos</h1>
          <h1 className="text-center text-4xl xl:text-5xl font-semibold mt-4 xl:mt-5 text-ibk geometria">S/ 1,000</h1>
          <h1 className="text-center text-sm xl:text-base font-medium mt-4 xl:mt-10 montserrat">
            Si ahorras <span className="font-semibold">S/200</span> durante 1 mes y lo dejas como garantía (S/100 cada quincena).
          </h1>

          <h1 className="text-center text-xs xl:text-base mt-10 xl:mt-9 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

          <div className="grid grid-cols-2 bg-crema px-2.5 xl:px-4 py-6 mt-2.5 xl:mt-4 rounded-md">
            <h1 className="text-xs xl:text-sm font-semibold montserrat">Te prestamos</h1>
            <h1 className="text-xs xl:text-sm font-normal montserrat">S/1,000</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">(*)Tú pagas</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">Aprox. S/106 cada mes</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">Durante</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">12 meses</h1>
          </div>

          <button
            className="py-4 px-4 xl:px-16 mt-4 xl:mt-7 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat"
            onClick={() => {
              window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/registro/1");
            }}
          >
            ¡Lo quiero!
          </button>
        </div>
        <div className="rounded-2xl bg-white px-3.5 xl:px-8 py-10 xl:py-12 shadow-sm">
          <h1 className="text-center text-lg xl:text-xl font-normal geometria">Te prestamos</h1>
          <h1 className="text-center text-4xl xl:text-5xl font-semibold mt-4 xl:mt-5 text-ibk geometria">S/ 3,000</h1>
          <h1 className="text-center text-sm xl:text-base font-medium mt-4 xl:mt-10 montserrat">
            Si ahorras <span className="font-semibold">S/600</span> durante 1 mes y lo dejas como garantía (S/300 cada quincena).
          </h1>

          <h1 className="text-center text-xs xl:text-base mt-10 xl:mt-9 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

          <div className="grid grid-cols-2 bg-crema px-2.5 xl:px-4 py-6 mt-2.5 xl:mt-4 rounded-md">
            <h1 className="text-xs xl:text-sm font-semibold montserrat">Te prestamos</h1>
            <h1 className="text-xs xl:text-sm font-normal montserrat">S/3,000</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">(*)Tú pagas</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">Aprox. S/317 cada mes</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">Durante</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">12 meses</h1>
          </div>

          <button
            className="py-4 px-4 xl:px-16 mt-4 xl:mt-7 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat"
            onClick={() => {
              window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/registro/2");
            }}
          >
            ¡Lo quiero!
          </button>
        </div>
        <div className="rounded-2xl bg-white px-3.5 xl:px-8 py-10 xl:py-12 shadow-sm">
          <h1 className="text-center text-lg xl:text-xl font-normal geometria">Te prestamos</h1>
          <h1 className="text-center text-4xl xl:text-5xl font-semibold mt-4 xl:mt-5 text-ibk geometria">S/ 5,000</h1>
          <h1 className="text-center text-sm xl:text-base font-medium mt-4 xl:mt-10 montserrat">
            Si ahorras <span className="font-semibold">S/1,500</span> durante 3 meses y lo dejas como garantía (S/500 cada mes).
          </h1>

          <h1 className="text-center text-xs xl:text-base mt-10 xl:mt-9 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

          <div className="grid grid-cols-2 bg-crema px-2.5 xl:px-4 py-6 mt-2.5 xl:mt-4 rounded-md">
            <h1 className="text-xs xl:text-sm font-semibold montserrat">Te prestamos</h1>
            <h1 className="text-xs xl:text-sm font-normal montserrat">S/5,000</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">(*)Tú pagas</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">Aprox. S/521 cada mes</h1>

            <h1 className="text-xs xl:text-sm font-semibold mt-2.5 montserrat">Durante</h1>
            <h1 className="text-xs xl:text-sm font-normal mt-2.5 montserrat">12 meses</h1>
          </div>

          <button
            className="py-4 px-4 xl:px-16 mt-4 xl:mt-7 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat"
            onClick={() => {
              window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/registro/3");
            }}
          >
            ¡Lo quiero!
          </button>
        </div>
      </div>

      <div className="block md:hidden mt-8 bg-green px-8">
        <div className="grid grid-cols-1">
          <div className="w-full rounded-2xl bg-white p-5 shadow-sm">
            <h1 className="text-center text-lg font-normal geometria">Te prestamos</h1>
            <h1 className="text-center text-4xl font-semibold mt-1 text-ibk geometria">S/ 1,000</h1>
            <h1 className="text-center text-sm font-medium mt-3 montserrat">
              Si ahorras <span className="font-semibold">S/200</span> durante 1 mes y lo dejas como garantía (S/100 cada quincena).
            </h1>

            <h1 className="text-center text-xs mt-6 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

            <div className="grid grid-cols-2 bg-crema p-3 mt-2.5 rounded-md">
              <h1 className="text-xs font-semibold montserrat">Te prestamos</h1>
              <h1 className="text-xs font-normal montserrat">S/1,000</h1>

              <h1 className="text-xs font-semibold mt-4 montserrat">(*)Tú pagas</h1>
              <h1 className="text-xs font-normal mt-4 montserrat">Aprox. S/106 cada mes</h1>

              <h1 className="text-xs font-semibold mt-4 montserrat">Durante</h1>
              <h1 className="text-xs font-normal mt-4 montserrat">12 meses</h1>
            </div>

            <button
              className="py-3 px-10 mt-6 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat"
              onClick={() => {
                window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/registro/1");
              }}
            >
              ¡Lo quiero!
            </button>
          </div>
          <div className="w-full rounded-2xl bg-white p-5 shadow-sm mt-8">
            <h1 className="text-center text-lg font-normal geometria">Te prestamos</h1>
            <h1 className="text-center text-4xl font-semibold mt-1 text-ibk geometria">S/ 3,000</h1>
            <h1 className="text-center text-sm font-medium mt-3 montserrat">
              Si ahorras <span className="font-semibold">S/600</span> durante 1 mes y lo dejas como garantía (S/300 cada quincena).
            </h1>

            <h1 className="text-center text-xs mt-6 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

            <div className="grid grid-cols-2 bg-crema p-3 mt-2.5 rounded-md">
              <h1 className="text-xs font-semibold montserrat">Te prestamos</h1>
              <h1 className="text-xs font-normal montserrat">S/3,000</h1>

              <h1 className="text-xs font-semibold mt-4 montserrat">(*)Tú pagas</h1>
              <h1 className="text-xs font-normal mt-4 montserrat">Aprox. S/317 cada mes</h1>

              <h1 className="text-xs font-semibold mt-4 montserrat">Durante</h1>
              <h1 className="text-xs font-normal mt-4 montserrat">12 meses</h1>
            </div>

            <button
              className="py-3 px-10 mt-6 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat"
              onClick={() => {
                window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/registro/2");
              }}
            >
              ¡Lo quiero!
            </button>
          </div>
          {/*<div className="w-full rounded-2xl bg-white p-5 shadow-sm mt-8">
                            <h1 className="text-center text-lg font-normal geometria">Te prestamos</h1>
                            <h1 className="text-center text-4xl font-semibold mt-1 text-ibk geometria">S/ 5,000</h1>
                            <h1 className="text-center text-sm font-medium mt-3 montserrat">Si ahorras <span className="font-semibold">S/1,500</span> durante 3 meses y lo dejas como garantía (S/500 cada mes).</h1>

                            <h1 className="text-center text-xs mt-6 font-semibold montserrat">¿Cómo obtengo el préstamo?</h1>

                            <div className="grid grid-cols-2 bg-crema p-3 mt-2.5 rounded-md">
                                <h1 className="text-xs font-semibold montserrat">Te prestamos</h1>
                                <h1 className="text-xs font-normal montserrat">S/5,000</h1>

                                <h1 className="text-xs font-semibold mt-4 montserrat">(*)Tú pagas</h1>
                                <h1 className="text-xs font-normal mt-4 montserrat">Aprox. S/521 cada mes</h1>

                                <h1 className="text-xs font-semibold mt-4 montserrat">Durante</h1>
                                <h1 className="text-xs font-normal mt-4 montserrat">12 meses</h1>
                            </div>

                            <button className="py-3 px-10 mt-6 w-full rounded-md bg-blue hover:bg-[#2A54A6] text-white text-base font-semibold montserrat" onClick={() => {window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port+"/registro/3");}}>Me interesa</button>
                        </div>*/}
        </div>
      </div>

      <h1 className="text-sm text-white mt-6 montserrat mx-8 md:mx-0">(*)TCEA 50.90%. Este es el interés que pagarás por el costo del préstamo.</h1>
    </div>
  );
};

export { Plans };
