import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import logoDesktop from "../../static/chambahorro_logo_1.svg";
import logoMobile from "../../static/chambahorro_logo_2.svg";
import { useEffect } from "react";
import { eventOne } from "../../static/facebookPixelEvents";
import { Steps } from "../../components/Steps";
import { WhatsApp } from "../../components/WhatsApp";
import { Cookies } from "../../components/Cookies";
import { useCookies } from 'react-cookie';
import  icons  from '../../static/chambahorro_icons.svg';

const Registry3 = () => {
    const [cuenta, setCuenta] = useState('');
    const [cookies, setCookies] = useCookies();

    useEffect(() => {
        //eventOne();
    }, [])

    const goLanding = () => {
        window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
    }

    const sendInfo = () => {

        goLanding();
    }

    return (
        <div>
            <div className="bg-white w-full py-3 md:py-3.5 xl:py-4 px-8 md:px-10 xl:px-48 flex items-center content-center">
                <img className="hidden md:block cursor-pointer" src={logoDesktop} onClick={goLanding} />
                <img className="block md:hidden h-10 w-28" src={logoMobile} onClick={goLanding} />
            </div>

            <Steps></Steps>

            <div className="px-8 md:px-10 xl:px-96">
                <img className="w-28 md:w-28 xl:w-40 mt-14 xl:mt-12" src={require('../../static/chambahorro_celular.png')} />

                <h1 className="text-2xl md:text-xl xl:text-2xl font-semibold mt-4 geometria">¡Hemos recibido tus datos!</h1>

                <h1 className="text-base md:text-base xl:text-base font-normal mt-4 geometria">En un plazo máximo de 48 horas nos <span className="font-bold">comunicaremos contigo por WhatsApp para confirmarte la aprobación de tu solicitud.</span></h1>

                <h1 className="text-base md:text-base xl:text-base mt-4 geometria">¿Cómo vamos?</h1>

                <div className="w-100 mt-8 flex items-stretch">
                    <div className="flex items-start">
                        <img src={icons} />
                    </div>
                    <div className="ml-5 flex flex-col justify-between">
                        <h1 className="text-base md:text-base text-ibk font-bold montserrat">Registra tus datos y abre una cuenta</h1>
                        <h1 className="text-base md:text-base text-ibk font-bold montserrat">Envía tu solicitud</h1>
                        <h1 className="text-base md:text-base montserrat">Solicitud en proceso de aprobación</h1>
                        <h1 className="text-base md:text-base montserrat">Recibe el mensaje de bienvenida</h1>
                    </div>
                </div>

                <div className="w-100 bg-minisky rounded-lg p-4 text-justify flex mt-12 xl:mt-8 mb-10 xl:mb-14">
                    <img className="w-6 h-6" src={require('../../static/chambahorro_icon5.png')} />
                    <h1 className="text-sm text-gray ml-2">Aún no deposites tu ahorro en la cuenta hasta que nos contactemos contigo.</h1>
                </div>

                <div className="w-full xl:w-fit mx-auto">
                    <button className="w-full xl:w-fit py-4 md:py-2 xl:py-4 px-4 md:px-4 xl:px-4 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-sm xl:text-base leading-5 font-semibold montserrat" onClick={goLanding}>
                        Regresa a Chambahorro
                    </button>
                </div>
                

                <h1 className="text-sm montserrat mt-6 xl:mt-7 mb-12 md:mb-6 xl:mb-12">Somos un producto de Interbank y no vamos a pedirte información sensible como números de tarjeta ni contraseñas.</h1>
            </div>

            <hr></hr>

            <WhatsApp cookies={cookies} setCookies={setCookies}></WhatsApp>

            <Footer></Footer>
        </div>
    )
}

export { Registry3 }