import React, { useState } from "react";
import { Footer } from "../../components/Footer";
import background from "../../static/chambahorro_rectangle3.jpg";
import logoDesktop from "../../static/chambahorro_logo_1.svg";
import logoMobile from "../../static/chambahorro_logo_2.svg";
import { ModalSuccess } from "../../components/ModalSuccess";
import { Form } from "../../components/Form";
import { ModalLoad } from "../../components/ModalLoad";
//import { useEffect } from "react";
//import { eventOne } from "../../static/facebookPixelEvents";

const Registry = () => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(0);

  /*useEffect(() => {
        eventOne();
    }, [])*/

  const goLanding = () => {
    window.location.replace(window.location.protocol + "//" + window.location.hostname + ":" + window.location.port);
  };

  return (
    <div>
      <div className="bg-white w-full py-3 md:py-3.5 xl:py-4 px-8 md:px-10 xl:px-48 flex items-center content-center">
        <img className="hidden md:block cursor-pointer" src={logoDesktop} onClick={goLanding} />
        <img className="block md:hidden h-10 w-28" src={logoMobile} onClick={goLanding} />
      </div>

      <div
        className="w-full px-8 md:px-10 xl:px-48"
        style={{ backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%" }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4 xl:gap-8">
          <div className="bg-white rounded-lg pt-5 xl:pt-8 my-11">
            <Form setResult={setResult} setLoading={setLoading} setShowModal={setShowModal}></Form>
          </div>
          <div className="w-full hidden md:block relative">
            <img className="w-9/12 absolute bottom-0 right-0" src={require("../../static/chambahorro_registro.png")} />
          </div>
        </div>
      </div>

      <Footer></Footer>

      {loading ? <ModalLoad /> : null}

      {result === 1 ? <ModalSuccess setResult={setResult} goLanding={goLanding} /> : null}

      {result === 2 ? <ModalSuccess setResult={setResult} /> : null}
    </div>
  );
};

export { Registry };
