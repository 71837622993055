import React, { useState } from "react";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Question = (props) => {

    const [show, setShow] = useState(0);
    
    const [preguntas, setPreguntas] = useState([
        {
            id: 1,
            pregunta: '¿Qué es Chambahorro?',
            respuesta: 'Chambahorro es un préstamo para tu negocio o para lo que necesites, que podrás obtener si cumples tus metas de ahorro y lo dejas como garantía Este producto se encuentra en fase piloto y estamos en un periodo de aprendizaje para brindarte la mejor experiencia.',
        },
        {
            id: 2,
            pregunta: '¿Cómo funciona Chambahorro?',
            respuesta: '',
        },
        {
            id: 3,
            pregunta: '¿Qué pasa si tengo deudas en el sistema financiero?',
            respuesta: 'Te recomendamos regularizar tu situación con otras entidades financieras y volver a solicitarnos una calificación dentro de 3 meses.',
        },
        {
            id: 4,
            pregunta: 'Cuando termine de pagar mi préstamo, ¿qué pasa con mi garantía?',
            respuesta: 'Una vez hayas pagado el total de tus cuota, te contactaremos en un máximo de 3 días hábiles para confirmarte que ya puedes disponer de la garantía dejaste en la cuenta de ahorros que acumulaste durante los 3 primeros meses.',
        },
        {
            id: 5,
            pregunta: '¿Qué pasa si no puedo pagar mi cuota a tiempo?',
            respuesta: 'Recuerda que si pagas a destiempo te cobrarán intereses moratorios, podrías ser reportado negativamente en la Central de Riesgos. Si dejas de pagar el préstamo, tus ahorros serán tomados como parte del pago.',
        },
        {
            id: 6,
            pregunta: '¿Qué pasa si no puedo completar el ahorro?',
            respuesta: 'El monto que hayas logrado ahorrar es tuyo y puedes usarlo como desees, pero no podremos darte ese préstamo que tanto querías. Tranquilo podemos volver a intentarlo después de 30 días.',
        },
        {
            id: 7,
            pregunta: '¿Mis ahorros en garantía, ganan intereses?',
            respuesta: 'No. Recuerda que la Cuenta Simple no genera intereses.'
        },
        {
            id: 8,
            pregunta: '¿Qué es una garantía?',
            respuesta: 'Es el dinero que dejas en tu cuenta y el banco conserva de manera temporal hasta que pagues todas las cuotas de tu préstamo. En Chambahorro, tu garantía es el monto que ahorraste antes de recibir tu préstamo.',
        },
        {
            id: 9,
            pregunta: '¿Qué es la tasa de interés moratoria?',
            respuesta: 'Es la tasa de interés nominal anual, cantidad que te cobran si no cumples con pagar las cuotas de tu préstamo a tiempo o si las pagas incompletas. Para evitar que te cobren intereses moratorios, recuerda pagar tu cuota en la fecha de pago elegida.'
        },
        {
            id: 10,
            pregunta: '¿Qué es una cuota y cómo debo pagarla?',
            respuesta: 'Es la cantidad en soles que debes pagar mensualmente según el préstamo elegido y puedes pagarlo por APP de Interbank o en cualquiera de las Tiendas Interbank a nivel nacional.',
        },
        {
            id: 11,
            pregunta: '¿Qué es plazo?',
            respuesta: 'Es el número de meses en el que deberás pagar tus cuotas.',
        },
        {
            id: 12,
            pregunta: '¿Puedo retirar el monto ahorrado en cualquier momento?',
            respuesta: 'Si puedes retirar tu ahorro, pero perderás la oportunidad de acceder al préstamo y deberás empezar a ahorrar de nuevo en caso quieras solicitar el préstamo. ¡Vamos, ponle punche Chambero, tú puedes!',
        },
    ]);

    const showAnswer = (id) => {
        if (id == show) {
            setShow(0);
        }
        else {
            setShow(id);
        }
    }

    return (
        <div className="w-full py-10 md:py-15 xl:py-18 md:py-28 px-8 md:px-10 xl:px-48">
            <h1 className="text-3xl md:text-mxl text-center font-normal geometria mb-0 md:mb-10 xl:mb-8">Si te quedaron dudas, <span className="hidden md:inline-block">revisa algunas preguntas frecuentes</span></h1>
            <h1 className="block md:hidden text-3xl md:text-mxl text-center font-normal mt-21 mb-9 geometria">revisa algunas preguntas frecuentes</h1>

            {preguntas.map((question) => (
                <div className="w-full mt-8 md:mt-9 xl:mt-10" key={question.id}>
                    <div className='text-base text-gray font-bold rounded-lg cursor-pointer flex items-center montserrat'
                        onClick={() => showAnswer(question.id)}>
                        <div className={show == question.id ? "text-ibk font-bold" : "font-bold"}>{question.pregunta}</div>
                        <div className="float-right ml-auto">{show != question.id ? <FontAwesomeIcon className="text-xl font-bold" icon={faChevronDown} /> : <FontAwesomeIcon className="text-xl font-bold text-ibk" icon={faChevronUp} />}</div>
                    </div>
                    <div className='text-base text-justify mt-8 montserrat' hidden={show != question.id}>{ question.respuesta }</div>
                    {question.id == 2 ? 
                        <ol className="mt-8" hidden={show != question.id}>
                            <li className='text-base text-justify montserrat'>1. Debes escoger un plan chambero y dejarlo como garantía.</li>
                            <li className='text-base text-justify montserrat'>2. Cuando completes el ahorro te depositaremos el monto que elegiste.</li>
                            <li className='text-base text-justify montserrat'>3. Finalmente, cuando cumplas el pago del préstamo en 12 meses, podrás utilizar el ahorro que dejaste al inicio.</li>
                        </ol>: 
                    null}
                    <hr className="mt-8 bg-gray" hidden={question.id == 12}></hr>
                </div>
            ))}
        </div>
    )
}

export { Question }