import hw1 from "../static/chambahorro_hw1.svg";
import hw2 from "../static/chambahorro_hw2.svg";
import hw3 from "../static/chambahorro_hw3.svg";
import { Link, Element, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

const HowWork = (props) => {

    return (
        <div className="w-full py-12 md:py-14 xl:py-20 px-8 md:px-26 xl:px-48 bg-blue">
            <div className="block xl:hidden">
                <h1 className="text-3xl md:text-4xl font-normal text-white geometria text-center">¿Cómo funciona Chambahorro?</h1>

                <div className="w-full mt-14 md:mt-10 xl:mt-22">
                    <img className="w-20 md:w-10 h-20 md:h-10 mx-auto" src={hw1} />
                    <div className="text-white">
                        {/*<h1 className="text-4xl font-semibold montserrat text-center mt-7">1.</h1>*/}
                        <h1 className="text-xl font-semibold montserrat text-center mt-5">1. Elije un plan chambero y ahorra</h1>
                        <h1 className="text-sm font-normal montserrat text-center mt-4 md:mt-2.5">Elige el préstamo y el plan de ahorro que se acomode a tus necesidades.</h1>
                        <hr className="my-10 md:my-8 w-full"></hr>
                    </div>
                </div>

                <div className="w-full">
                    <img className="w-20 md:w-10 h-20 md:h-10 mx-auto" src={hw2} />
                    <div className="text-white">
                        {/*<h1 className="text-4xl font-semibold montserrat text-center mt-7">2.</h1>*/}
                        <h1 className="text-xl font-semibold montserrat text-center mt-5">2. Deja tu ahorro como garantía y recibe tu préstamo</h1>
                        <h1 className="text-sm font-normal montserrat text-center mt-4 md:mt-2.5">Deposita tu ahorro, será tu garantía para calificar.</h1>
                        <hr className="my-10 md:my-8 w-full"></hr>
                    </div>
                </div>

                <div className="w-full">
                    <img className="w-20 md:w-10 h-20 md:h-10 mx-auto" src={hw3} />
                    <div className="text-white">
                        {/*<h1 className="text-4xl font-semibold montserrat text-center mt-7">3.</h1>*/}
                        <h1 className="text-xl font-semibold montserrat text-center mt-5">3. Paga tu préstamo y libera tu ahorro</h1>
                        <h1 className="text-sm font-normal montserrat text-center mt-4 md:mt-2.5">Paga las 12 cuotas mensuales y recibirás tu ahorro de vuelta.</h1>
                    </div>
                </div>
            </div>

            <div className="hidden xl:block">
                <h1 className="text-5xl font-normal text-white geometria text-center mb-20">¿Cómo funciona Chambahorro?</h1>

                <div className="grid grid-cols-3 gap-32">
                    <div className="w-full">
                        <div className="text-white">
                            <img className="w-36 h-36 mx-auto" src={hw1} />
                            {/*<div className='text-5xl text-center font-bold montserrat mt-7'>1.</div>*/}
                            <h1 className="text-xl text-center font-semibold montserrat mt-6">1. Elige un plan chambero y ahorra</h1>
                            <h1 className="text-base text-center font-normal montserrat mt-4">Elige el préstamo y el plan de ahorro que se acomode a tus necesidades.</h1>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="text-white">
                            <img className="w-36 h-36 mx-auto" src={hw2} />
                            {/*<div className='text-5xl text-center font-bold montserrat mt-7'>2.</div>*/}
                            <h1 className="text-xl text-center font-semibold montserrat mt-6">2. Deja tu ahorro como garantía y recibe tu préstamo</h1>
                            <h1 className="text-base text-center font-normal montserrat mt-4">Deposita tu ahorro, será tu garantía para calificar.</h1>
                        </div>
                    </div>

                    <div className="w-full">
                        <div className="text-white">
                            <img className="w-36 h-36 mx-auto" src={hw3} />
                            {/*<div className='text-5xl text-center font-bold montserrat mt-7'>3.</div>*/}
                            <h1 className="text-xl text-center font-semibold montserrat mt-6">3. Paga tu préstamo y libera tu ahorro</h1>
                            <h1 className="text-base text-center font-normal montserrat mt-4">Paga las 12 cuotas mensuales y recibirás tu ahorro de vuelta.</h1>
                        </div>
                    </div>
                </div>
            </div>
            <Element name="requisitos">&nbsp;</Element>
        </div>
    )
}

export { HowWork }