import { } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
//import { useEffect } from "react";
//import { eventTwo } from "../static/facebookPixelEvents";

const ModalSuccess = (props) => {

    /*useEffect(() => {
        eventTwo();
    }, [])*/

    return (
        <div className="flex px-4 py-4 md:py-0 xl:py-4 justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-700 bg-opacity-75 transition-opacity">
            <div className="relative w-140 ">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="grid justify-items-end p-3 md:p-1 xl:p-3 rounded-t">
                        <button className="bg-transparent border-0" onClick={() => {props.setResult(0); props.goLanding();}}>
                            <FontAwesomeIcon className="opacity-7 h-6 xl:h-8 w-6 xl:w-8 text-xl block" icon={faClose} />
                        </button>
                    </div>

                    <div className="mx-8 md:mx-20 text-center">
                        <img className="mr-auto ml-auto w-max md:w-60 xl:w-max" src={require('../static/chambahorro_celular.png')} />

                        <h1 className="text-lg md:text-xl xl:text-2xl text-center font-semibold mt-4 geometria">¡Hemos recibido tus datos!</h1>

                        <h1 className="text-sm md:text-base xl:text-lg text-center font-normal mt-4 geometria">Pronto nos estaremos comunicando contigo para brindarte mayor información.</h1>

                        <div className="w-100 bg-amber-50 rounded-lg py-1 px-2 text-justify flex items-center mt-4">
                            <img src={require('../static/chambahorro_icon5.png')} /> 
                            <h1 className="text-gray ml-2" style={{fontSize: 10}}>Recuerda que somos un producto de Interbank y no vamos a pedirte información sensible como numeros de tarjeta ni contraseñas.</h1>
                        </div>

                        <button className="py-4 md:py-2 xl:py-4 px-20 md:px-16 xl:px-20 mb-12 md:mb-6 xl:mb-12 mt-8 md:mt-8 xl:mt-8 rounded-md bg-blue hover:bg-[#2A54A6] text-white text-sm xl:text-base leading-5 font-semibold montserrat" onClick={() => {props.setResult(0); props.goLanding();}}>
                            Entendido
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { ModalSuccess }