function toFormData(data) {
  const formData = new FormData();

  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  return formData;
}

export { toFormData };
